import { Controller as BaseController } from "@hotwired/stimulus";
import "instantsearch.css/themes/reset.css";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";
import {
  configure,
  currentRefinements,
  infiniteHits,
  poweredBy,
  searchBox,
  stats,
} from "instantsearch.js/es/widgets";
import filters from "./search_filters_widgets";
import hitsTemplates from "./search_hits_templates";
import { Turbo } from "@hotwired/turbo-rails";
import I18n from "../../../../app/javascript/i18n";
export class Controller extends BaseController {
  static targets = [
    "filters",
    "searchBox",
    "hits",
    "originalContent",
    "refinements",
    "stats",
    "credits",
  ];
  static values = {
    apiKey: String,
    applicationId: String,
    index: String,
    indexKey: String,
    defaultFacet: String,
  };

  initialize() {
    this.initAlgolia();
    this.initWidgets();
  }

  connect() {
    this.search.start();
  }

  disconnect() {
    this.search.dispose();
  }

  initAlgolia() {
    const searchClient = algoliasearch(
      this.applicationIdValue,
      this.apiKeyValue
    );

    const hitsContainer = this.hitsTarget;
    const statsContainer = this.statsTarget;
    const creditsContainer = this.creditsTarget;
    const { originalItems } = this;

    this.search = instantsearch({
      indexName: this.indexValue,
      numberLocale: I18n.locale,
      routing: {
        router: history({
          cleanUrlOnDispose: false,
          push: (url) => {
            Turbo.navigator.history.push(new URL(url));
          },
        }),
      },
      // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#algolia-search-helper
      searchFunction: (helper) => {
        const { state } = helper;
        const isSearching = !(
          state.query === "" &&
          Object.keys(state.disjunctiveFacetsRefinements).length === 0 &&
          Object.keys(state.facetsRefinements).length === 0 &&
          Object.keys(state.hierarchicalFacetsRefinements).length === 0 &&
          Object.keys(state.numericRefinements).length === 0 &&
          state.tagRefinements.length === 0
        );

        if (isSearching) {
          hitsContainer.style.display = "";
          statsContainer.style.display = "";
          creditsContainer.style.display = "";
          originalItems.style.display = "none";
        } else {
          hitsContainer.style.display = "none";
          statsContainer.style.display = "none";
          creditsContainer.style.display = "none";
          originalItems.style.display = "";
        }

        helper.search();
      },
      searchClient,
    });
  }

  initWidgets() {
    this.searchBoxTargets.forEach((sb) => {
      const searchBoxInHeader = sb.classList.contains("is-in-header");

      sb.innerHTML = "";

      this.search.addWidgets([
        searchBox({
          autofocus: false,
          container: sb,
          placeholder: sb.getAttribute("data-placeholder"),
          showLoadingIndicator: false,
          cssClasses: {
            form: "search-field-form",
            input: "search-field-input",
            reset: "search-field-reset",
            submit: `search-field-submit ${
              searchBoxInHeader ? "button is-red" : ""
            }`,
          },
          templates: {
            submit(data, { html }) {
              return html`<svg class="c-icon-root c-icon-search button-icon">
                <use xlink:href="#c-icon-search"></use>
              </svg>`;
            },
          },
        }),
      ]);
    });

    this.search.addWidgets([
      currentRefinements({
        container: this.refinementsTarget,
        excludedAttributes: [],
        transformItems: (items) =>
          items.map((item) => ({
            ...item,
            label: this.translatedLabel(item.label),
          })),
      }),
      stats({
        container: this.statsTarget,
        templates: {
          text: (data) => this.statsTemplate(data),
        },
      }),
    ]);

    if (this.defaultFacetValue) {
      // TODO: https://www.algolia.com/doc/guides/building-search-ui/upgrade-guides/js/#configure
      this.search.addWidgets([
        configure({
          facetFilters: this.defaultFacetValue,
        }),
      ]);
    }

    const showMoreText = this.hitsTarget.getAttribute("data-show-more");
    const showMore = {
      showMoreText(data, { html }) {
        return html`<span class="button-text">${showMoreText}</span>`;
      },
    };

    this.search.addWidgets([
      infiniteHits({
        container: this.hitsTarget,
        cssClasses: {
          loadMore: "button is-red",
        },
        templates: { ...hitsTemplates[this.indexKeyValue], ...showMore },
      }),
      // Powered by Algolia
      poweredBy({
        container: this.creditsTarget,
      }),
    ]);

    // Add filters for current index
    filters[this.indexKeyValue].forEach((filter) => {
      const filterEl = document.createRange().createContextualFragment(`
        <div class="search-filters-item">
          ${
            filter.title
              ? `<h3 class="search-filters-item-title">${filter.title}</h3>`
              : ""
          }
          <div class="search-filters-item-widget"></div>
        </div>
      `);
      const container = filterEl.querySelector(".search-filters-item-widget");
      this.filtersTarget.appendChild(filterEl);
      this.search.addWidgets([filter.widget(container)]);
    });
  }

  toggleFilters() {
    const filtersParentEl = this.filtersTarget.parentNode;

    filtersParentEl.classList.toggle("is-open");

    const visible = filtersParentEl.classList.contains("is-open");

    if (!visible) {
      filtersParentEl.scrollIntoView();
    }
  }

  translatedLabel(label) {
    const suffix = I18n.locale === "fr" ? " " : "";
    return I18n.t(`search.filters.${label}`) + suffix;
  }

  statsTemplate(data) {
    return `<div class="title is-smaller is-level-2">
      ${I18n.t("search.ui.results", { count: data.nbHits })}
      ${data.query ? ` ${I18n.t("search.ui.for")} "${data.query}"` : ""}
    </div>`;
  }

  get originalItems() {
    return this.originalContentTarget;
  }
}
