import I18n from "../../../../app/javascript/i18n";

const icons = ["bars", "curve", "line"];

const entryText = (hit, { html, components }) => {
  if (!hit.description && !hit.introduction && !hit.content) {
    return "";
  }

  return html`<div class="entry-text has-highlight">
    ${components.Snippet({ hit, attribute: "description" })}
    ${components.Snippet({ hit, attribute: "introduction" })}
    ${components.Snippet({ hit, attribute: "content" })}
  </div>`;
};

const highlightedTags = (hit, { html }) => {
  // eslint-disable-next-line no-underscore-dangle
  const highlightTags = hit._highlightResult.tags;
  if (!hit.tags) return "";
  return hit.tags.map(
    (tag, i) =>
      html`<li class="tags-item">
        <a href="${tag.href}">
          ${highlightTags ? highlightTags[i].text.value : tag.text}
        </a>
      </li>`
  );
};

export default {
  event: {
    item(hit, { html, components }) {
      return html`<div class="agenda-item without-borders">
        <a class="agenda-link is-small" href="${hit.path}">
          <div class="agenda-date-wrapper">
            <div class="agenda-date">${hit.human_date}</div>
          </div>
          <div class="agenda-content">
            <div class="agenda-headlines has-highlight">
              <span class="agenda-title">
                ${components.Highlight({ hit, attribute: "title" })}
              </span>
              <span class="agenda-location">
                ${components.Highlight({ hit, attribute: "place" })}
              </span>
            </div>
          </div>
          ${hit.image
            ? html`<div class="agenda-image"><img src="${hit.image}" /></div>`
            : ""}
        </a>
        <div class="tags is-in-agenda has-highlight">
          <ul class="tags-items">
            ${highlightedTags(hit, { html })}
          </ul>
        </div>
      </div>`;
    },
  },
  content: {
    item(hit, { html, components }) {
      return html`<div class="entry">
        <div class="entry-content">
          <a
            href="${hit.path || hit.url}"
            ${hit.url
              ? html` target="_blank" rel="noopener" class="is-external"
                title="${I18n.t("search.ui.new_window")}"`
              : ""}
          >
            <div class="entry-title has-highlight">
              ${components.Highlight({ hit, attribute: "title" })}${hit.url
                ? html`<svg class="c-icon-root c-icon-open">
                    <use xlink:href="#c-icon-open"></use>
                  </svg>`
                : ""}
            </div>
            <div class="entry-category">${hit.human_kind}</div>
          </a>
          ${entryText(hit, { html, components })}
          ${hit.date ? html`<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight">
            <ul class="tags-items">
              ${highlightedTags(hit, { html })}
            </ul>
          </div>
        </div>
      </div>`;
    },
  },
  news_flash: {
    item(hit, { html, components }) {
      return html`<div class="entry is-indicator has-icon">
        <a class="entry-image" href="${hit.url}">
          <svg class="c-icon-root c-icon-flash">
            <use xlink:href="#c-icon-flash"></use>
          </svg>
        </a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${components.Highlight({ hit, attribute: "title" })}
              <svg class="c-icon-root c-icon-open">
                <use xlink:href="#c-icon-open"></use>
              </svg>
            </div>
          </a>
          ${entryText(hit, { html, components })}
          ${hit.human_date
            ? html`<div class="entry-kind-and-date">
                <span class="entry-date">${hit.human_date}</span>
              </div>`
            : ""}
          <div class="tags is-in-entry has-highlight">
            <ul class="tags-items">
              ${highlightedTags(hit, { html })}
            </ul>
          </div>
        </div>
      </div>`;
    },
  },
  page: {
    item(hit, { html, components }) {
      return html`<div class="entry">
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${components.Highlight({ hit, attribute: "title" })}
            </div>
            <div class="entry-category">${hit.human_kind}</div>
          </a>
          ${entryText(hit, { html, components })}
          ${hit.date ? html`<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight">
            <ul class="tags-items">
              ${highlightedTags(hit, { html })}
            </ul>
          </div>
        </div>
      </div>`;
    },
  },
  indicator: {
    // TODO: add authors
    // TODO: add human date
    item(hit, { html, components }) {
      const icon = icons[Math.floor(Math.random() * icons.length)];
      return html`<div class="entry is-indicator has-icon">
        <a class="entry-image" href="${hit.path}">
          <svg class="c-icon-root c-icon-data-${icon}">
            <use xlink:href="#c-icon-data-${icon}"></use>
          </svg>
        </a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${components.Highlight({ hit, attribute: "title" })}
            </div>
          </a>
          ${entryText(hit, { html, components })}
          ${hit.date ? html`<div class="entry-date">${hit.date}</div>` : ""}
          <div class="tags is-in-entry has-highlight">
            <ul class="tags-items">
              ${highlightedTags(hit, { html })}
            </ul>
          </div>
        </div>
      </div>`;
    },
  },
  person: {
    item(hit, { html, components }) {
      return html`<div class="team-item without-borders">
        <a class="team-item-content" href="${hit.path}">
          <div class="team-details has-highlight">
            <div class="team-name">
              ${components.Highlight({ hit, attribute: "full_name" })}
              <svg class="c-icon-root c-icon-angle-right">
                <use xlink:href="#c-icon-angle-right"></use>
              </svg>
            </div>
            <div class="team-title">
              ${components.Highlight({ hit, attribute: "title" })}
            </div>
            <div class="team-subtitle">
              ${components.Highlight({ hit, attribute: "sub_title" })}
            </div>
          </div>
          <div class="team-image">
            <img alt="" src="${hit.image}" />
          </div>
        </a>
      </div>`;
    },
  },
  publication: {
    item(hit, { html, components }) {
      return html`<div
        class="entry is-publication${hit.emphasized ? " is-emphasized" : ""}"
      >
        <a class="entry-image" href="${hit.path}">
          <img alt="" src="${hit.image}" />
        </a>
        <div class="entry-content">
          <a href="${hit.path}">
            <div class="entry-title has-highlight">
              ${components.Highlight({ hit, attribute: "title" })}
            </div>
          </a>
          ${hit.authors.length || hit.reference
            ? html`<div class="authors">
                ${hit.authors.length ? I18n.t("search.ui.by") : ""}
                ${hit.authors.map(
                  (author, i) =>
                    html` <a href="${author.href}">${author.name}</a>${i <
                      hit.authors.length - 1
                        ? ","
                        : ""}`
                )}
                ${hit.reference
                  ? html`<span class="authors-suffix">${hit.reference}</span>`
                  : ""}
              </div>`
            : ""}
          ${hit.published_at_human
            ? html`<div class="entry-date">${hit.published_at_human}</div>`
            : ""}
          ${hit.emphasized
            ? html`<a class="button" href="${hit.path}">
                <span class="button-text"
                  >${I18n.t("search.ui.know_more")}</span
                >
                <svg class="c-icon-root c-icon-arrow-right button-icon">
                  <use xlink:href="#c-icon-arrow-right"></use>
                </svg>
              </a>`
            : ""}
          <div class="tags is-in-entry has-highlight">
            <ul class="tags-items">
              ${highlightedTags(hit, { html })}
            </ul>
          </div>
        </div>
      </div>`;
    },
  },
};
